/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Vue from 'vue'
import router from './router'
// import store from './store'
import VueScrollTo from 'vue-scrollto'
import VueI18n from 'vue-i18n'
import VueEllipseProgress from 'vue-ellipse-progress'
import { XpedeoPlugin, Settings, Device } from '@xpedeo/core'
import NetworkErrorScreen from '@xpedeo/core/dist/NetworkErrorScreen'
// import './registerServiceWorker'
import './style/main.scss'
// import XpedeoNfcBeaconsPlugin from './plugins/xpedeo-nfc-beacons'

require('./env')

const defaultLocale = 'de_DE'
Vue.use(VueI18n)
Vue.use(VueEllipseProgress)
const i18n = new VueI18n({ locale: defaultLocale, fallbackLocale: defaultLocale, messages: { en_EN: require('./messages/en_EN.json'), de_DE: require('./messages/de_DE.json') } }) // eslint-disable-line
Vue.use(VueScrollTo, { container: 'div.js-page-content' })
Vue.use(XpedeoPlugin, {
  router,
  config: {
    defaultLocale,
    i18n,
    enableEva: true
  },
  plugins: {
    positioning: {
      engines: ['pseudo']
    },
    eva: {}
  }
})

Vue.config.productionTip = false

const initAndMount = async () => {
  let initError = false
  try {
    await Settings.initSettings()
  } catch (error) {
    initError = true
  }
  const app = new Vue({
    router,
    i18n,
    data () {
      return {
        isLoading: false,
        initError
      }
    },
    methods: {
      isCompatible () {
        return (typeof Object.__defineSetter__ === 'function') && !!String.prototype.includes
      },
      async reInit () {
        try {
          this.isLoading = true
          await Settings.initSettings()
          if (this.$route.path === '/' && !this.$xp.device.isLoan && this.$xp.settings.mergedSettings.content && this.$xp.settings.mergedSettings.content.startPageID) {
            this.$router.replace('/_/page/' + this.$xp.settings.mergedSettings.content.startPageID)
          } else if (this.$xp.device.platform === 'browser' && this.$xp.settings.mergedSettings.content && this.$xp.settings.mergedSettings.content.welcomeScreenId && this.$route.path !== '/') {
            // redirect to welcome screen
            this.$router.replace({ path: `/${this.$xp.polyglot.urlPath}page/${this.$xp.settings.mergedSettings.content.welcomeScreenId}`, query: { backToPath: this.$route.path } })
          }
          this.initError = false
        } catch (error) {
          this.initError = true
        } finally {
          this.isLoading = false
        }
      }
    },
    render: function (h) {
      if (this.isCompatible()) {
        if (this.isLoading) {
          return h('div', { class: { 'c-app--loading': true } })
        } else {
          return !this.initError ? h('app') : h(NetworkErrorScreen, {
            class: window.XPEDEO_SETTINGS.theme || 't-dark',
            on: {
              retry: this.reInit
            }
          })
        }
      } else {
        return h('p', {
          class: {
            problem: true
          }
        }, [
          'Leider funktioniert xpedeo nicht auf diesem Browser. Bitte benutzen Sie einen anderen, z.B. Chrome.<br><br>Sorry but xpedeo does not work on your browser. Please use a different one, i.e. Chrome.'
        ])
      }
    }
  })
  app.$mount('#app')
}

if (Device.platform === 'browser') {
  initAndMount()
} else {
  document.addEventListener('deviceready', initAndMount, false)
}
